<template>
  <section id="about">
    <div class="row q-mx-auto justify-between items-start">
      <div class="col q-pr-md" id="about">
        <h1>About Historiana</h1>
        <h5>
          Historiana is an on-line educational multimedia tool that offers
          students multi-perspective, cross-border and comparative historical
          sources to supplement their national history textbooks.
        </h5>
        <p>
          Historiana&nbsp;might be considered as a digital alternative to a
          European textbook, however the website does not attempt to present a
          comprehensive ‘story of Europe’ and its relationship with the rest of
          the world. It offers a framework for comparing and contrasting the
          impact on and responses by Europe’s nations to a range of different
          events and developments which have shaped the world from the distant
          past to modern times.
        </p>
        <p>
          Historiana&nbsp;promotes the acquisition of cross-border historical
          knowledge and the development of critical thinking, digital and other
          transversal competences important for preparing a young generation for
          active participation as citizens of the 21
          <sup>st</sup> Century.
        </p>
    
        <h5>Join the Historiana Community</h5>
        <p>
          Are you interested in the development of Historiana? Would you like to
          stay up to date about new content and features? Or would you like to
          contribute to the continuous development of Historiana? Please join
          the
          <a
            href="https://www.facebook.com/groups/historiana/permalink/1359586110726889/"
            target="_blank"
          >Historiana Facebook group</a>. Contributions to Historiana Labs are welcomed, please contact us at
          <a
            ref="mailto:labs@historiana.eu"
          >labs@historiana.eu</a>. History
          educators and historians from more than 30 countries have actively
          contributed to the present on-line tool. Recently also, educators from
          India, Middle East, North Africa and the United States have expressed
          an interest in contributing to the website. Historiana will continue
          to grow as more and more contributors upload suitable material.
        </p>
    
        <h5>The Team</h5>
        <p>
          <strong>
            <a href="http://euroclio.eu" target="_blank">
              <i class="fa fa-external-link" /> EuroClio
            </a>
            – European Association of History Educators
          </strong>, a community of educators who are committed to continuously improve
          the way history is being taught. EuroClio is run by a professional
          secretariat, which is supervised by a democratically elected board.
          EUROCLIO wants to enable and inspire educators to help their students
          become active and critical citizens through meaningful and inclusive
          education. EUROCLIO has over twenty years’ experience in organising
          trainings, managing (inter)-national projects and the collaborative
          design and testing of educational resources. EUROCLIO is coordinating
          the overall development of Historiana.
        </p>
        <p>
          <strong>
            <a href="https://webtic.eu" target="_blank">
              <i class="fa fa-external-link" /> Webtic
            </a>,
          </strong>is a company with a long history rooted in hacking and a great
          passion for design. Ever since the early start of the worldwide-web
          Webtic is building smart solutions based on open source server
          software and web technologies. A signature feature of Webtic’s work, is
          that they make use of the latest developments which enables them to
          see possibilities that haven’t been discovered by others.
        </p>
        <p>
          <strong>
            <a href="https://use.media/20years/" target="_blank">
              <i class="fa fa-external-link" /> USE.media
            </a>,
          </strong> is an interactive studio, from where Joes Koppers collaborates with many designers, artists and developers to create surprising, fluent, user friendly interfaces and experiences. Joes is an interaction designer, creative technologist and javascript developer.
        </p>
        <h5>Historiana blog</h5>
        <p>
          <a href="http://blog.historiana.eu" target="_blank">
            <i class="fa fa-external-link" /> Historiana blog
          </a>
          is a place to exchange information related to the use of digital
          technologies for the advancement of history education. Here insights
          will be shared by people working on the development of Historiana as
          well as information on (new) tools made by others with suggestions on
          how to use these to promote historical thinking through active
          learning.
        </p>
      </div>
      <div class="col-3" id="contact">
        <h1>Contact</h1>
        <p>
          The development of Historiana is coordinated by EuroClio – European
          Association of History Educators.
        </p>
        <hr />
        <p>
          For any question, remarks or suggestions related to Historiana please
          contact:
        </p>
        <p>
          <strong>Steven Stegers</strong>, Acting Executive Director
        </p>
        <p>
          EuroClio –
          <br />European Association of History Educators
        </p>
        <p>
          Riouwstraat 139
          <br />2585 HP
          <br />The Hague
          <br />The Netherlands
          <br />+31
          703817836
          <br />
          <a href="mailto:contact@historiana.eu">contact@historiana.eu</a>
          <br />or
          <br />
          <a href="mailto:steven@euroclio.eu">steven@euroclio.eu</a>
        </p>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "About"
};
</script>
<style scoped>
#about,
#contact {
  margin-bottom: 2em;
}
#about h1,
#contact h1 {
  margin-bottom: 0.5em;
  border-bottom: 1px solid;
}
#contact {
  background-color: #f5f5f5;
  padding: 0 20px;
}
a {
  color: #00aaee;
}
hr {
  border: 0;
  border-bottom: 1px dotted #959595;
  margin: 40px 0;
}
h5 {
  line-height: 1.5em;
}
</style>
